<template>
    <ODataLookup :data-object="dsDO" :noClear="true">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
        </template>
        <OColumn field="Name" width="200"></OColumn> 
        <OColumn field="Title" width="200"></OColumn>
        <OColumn field="CompetenceArea" width="200"></OColumn> 
        <OColumn field="Level" width="80"></OColumn> 
    </ODataLookup>
</template>

<script setup>
    import { defineProps, ref, computed } from 'vue';
    import { ODataLookup } from 'o365-datalookup';


    const props = defineProps({
        textInput: Boolean,
        is: String,
        textInputValue: String,        
    });

    const dsDO = $getOrCreateDataObject({
        id: 'dsDO' + crypto.randomUUID(),
        viewName: 'aviw_Resource_ExpertiseLevels',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields: [ 
            { name: "Name", type: "string" },
            { name: "Title", type: "string" },
            { name: "CompetenceAreaType", type: "string" },
            { name: "Level", type: "Number" },
        ]
    });
</script>